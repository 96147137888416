import { all } from "redux-saga/effects"
import {
  watch_add_attestation_saga,
  watch_get_all_attestation_cours_saga,
  watch_get_all_attestation_saga,
  watch_get_all_attestation_signe_saga,
  watch_get_attestation_cours_saga,
  watch_get_attestation_saga,
  watch_get_attestation_signe_saga,
  watch_get_types_saga,
  watch_validate_attestation_saga
} from "./attestation"
import {
  watch_add_clients_saga,
  watch_allocation_client_saga,
  watch_client_date_controller_saga,
  watch_client_user_stat_saga,
  watch_depense_client_saga,
  watch_detail_client_saga,
  watch_facture_client_saga,
  watch_liste_clients_saga,
  watch_liste_contacts_saga,
  watch_rapport_client_saga,
  watch_ticker_client_saga,
  watch_update_client_saga
} from "./client"
import {
  watch_add_conge_saga,
  watch_annuler_conge_saga,
  watch_edit_conge_saga,
  watch_liste_all_conge_rejete_saga,
  watch_liste_all_conge_saga,
  watch_liste_all_conge_valide_saga,
  watch_liste_conge_annulee_saga,
  watch_liste_conge_rejete_saga,
  watch_liste_conge_saga,
  watch_liste_conge_valide_saga,
  watch_liste_solde_saga,
  watch_liste_solde_team_saga,
  watch_liste_sub_conge_rejete_saga,
  watch_liste_sub_conge_saga,
  watch_liste_sub_conge_valide_saga,
  watch_upload_conge_saga,
  watch_used_solde_saga,
  watch_validate_conge_saga,
  watch_liste_conge_type_saga
} from "./conge"
import {
  watch_create_demande_explication_saga,
  watch_create_reponse_explication_saga,
  watch_liste_explication_author_saga,
  watch_liste_explication_saga,
  watch_liste_rapport_user_ticker_saga,
  watch_update_demande_explication_saga
} from "./explication"
import {
  watch_add_facture_saga,
  watch_entite_saga,
  watch_facture_ticker_saga,
  watch_liste_allocation_offre_facture_saga,
  watch_liste_allocation_offre_saga,
  watch_liste_facturation_saga,
  watch_liste_facture_saga,
  watch_revenue_liste_saga,
  watch_statistique_facture_saga,
  watch_statut_facture_saga,
  watch_update_facture_saga
} from "./facture"
import {
  watch_add_facture_fournisseur_saga,
  watch_add_files_fournisseurs_saga,
  watch_add_fournisseurs_saga,
  watch_info_fournisseurs_saga,
  watch_liste_allocation_offre_fournisseur_saga,
  watch_liste_categorie_fournisseurs_saga,
  watch_liste_contact_fournisseurs_saga,
  watch_liste_custom_values_fournisseurs_saga,
  watch_liste_custom_values_saga,
  watch_liste_facture_fournisseur_saga,
  watch_liste_files_fournisseurs_saga,
  watch_liste_fournisseurs_all_saga,
  watch_liste_fournisseurs_saga,
  watch_liste_sous_categorie_fournisseurs_saga,
  watch_statut_facture_fournisseur_saga,
  watch_update_facture_fournisseur_saga,
  watch_update_files_fournisseurs_saga,
  watch_update_fournisseurs_saga,
  watch_liste_allocation_offre_facture_fournisseur_saga,
  watch_add_contacts_fournisseurs_saga
} from "./fournisseur"
import {
  watch_add_maladie_saga,
  watch_edit_maladie_saga,
  watch_list_all_maladie_saga,
  watch_list_all_maladie_uploade_saga,
  watch_list_all_maladie_valide_saga,
  watch_list_maladie_saga,
  watch_list_maladie_uploade_saga,
  watch_list_maladie_valide_saga
} from "./maladie"
import {
  watch_add_marque_saga,
  watch_allocation_marque_saga,
  watch_depense_marque_saga,
  watch_detail_marque_saga,
  watch_facture_marque_saga,
  watch_liste_marques_saga,
  watch_marque_date_controller_saga,
  watch_marque_user_stat_saga,
  watch_update_marque_saga
} from "./marques"
import {
  watch_create_non_staffe_saga,
  watch_liste_team_disponible_saga,
  watch_update_non_staffe_saga,
  watch_user_disponible_saga
} from "./nonStaffe"
import {
  watch_categorie_remboursement_saga,
  watch_create_order_saga,
  watch_create_refund_saga,
  watch_delete_order_saga,
  watch_delete_refund_saga,
  watch_get_moyen_paiement_saga,
  watch_get_moyen_remboursement_saga,
  watch_get_ordre_mission_saga,
  watch_get_remboursement_rejete_saga,
  watch_get_remboursement_rembourse_saga,
  watch_get_remboursement_saga,
  watch_get_remboursement_valide_saga,
  watch_statut_ordre_saga,
  watch_statut_remboursement_saga,
  watch_update_order_saga,
  watch_update_refund_saga
} from "./noteFrais"
import {
  watch_add_am_ticker_saga,
  watch_add_contact_ticker_saga,
  watch_add_membre_ticker_saga,
  watch_add_ticker_saga,
  watch_allocation_ticker_saga,
  watch_am_ticker_saga,
  watch_contact_ticker_saga,
  watch_date_am_ticker_saga,
  watch_depense_ticker_saga,
  watch_edit_am_ticker_saga,
  watch_edit_contact_ticker_saga,
  watch_edit_membre_ticker_saga,
  watch_info_ticker_saga,
  watch_liste_am_saga,
  watch_liste_bu_saga,
  watch_liste_categories_saga,
  watch_liste_client_saga,
  watch_liste_lettre_ticker_saga,
  watch_liste_marque_saga,
  watch_liste_marque_search_saga,
  watch_liste_statuts_tickers_saga,
  watch_liste_tickers_alertes_saga,
  watch_liste_tickers_saga,
  watch_liste_tickers_search_saga,
  watch_liste_tickers_search_value_saga,
  watch_liste_tickers_super_user_saga,
  watch_liste_type_categories_saga,
  watch_liste_user_team_saga,
  watch_liste_users_saga,
  watch_membre_ticker_saga,
  watch_mes_tickers_15j_saga,
  watch_mes_tickers_15j_stat_saga,
  watch_mes_tickers_1mois_saga,
  watch_mes_tickers_marques_saga,
  watch_mes_tickers_passif_saga,
  watch_mes_tickers_sans_activite_saga,
  watch_send_message_retard_saga,
  watch_ticker_date_controller_saga,
  watch_ticker_user_stat_saga,
  watch_update_ticker_saga,
  watch_year_info_ticker_saga,
} from "./ticker"
import {
  watch_day_before_saga,
  watch_next_day_saga,
  watch_publish_timesheet_saga,
  watch_suivi_timesheet_saga,
  watch_ticker_admin_saga,
  watch_ticker_saved_saga
} from "./timesheet"
import {
  watch_edit_user_saga,
  watch_liste_permission_saga,
  watch_liste_team_retard_saga,
  watch_liste_team_saga,
  watch_liste_user_times_download_saga,
  watch_liste_user_times_saga,
  watch_liste_users_trombino_saga,
  watch_login_saga,
  watch_user_date_controller_saga,
  watch_user_info_saga,
  watch_user_stat_saga
} from "./user"

export default function* rootSaga() {
  yield all([
    // Login
    watch_login_saga(),
    watch_liste_permission_saga(),
    watch_user_info_saga(),
    watch_edit_user_saga(),
    watch_liste_users_trombino_saga(),
    watch_liste_team_saga(),
    watch_liste_team_retard_saga(),
    watch_liste_user_times_saga(),
    watch_user_stat_saga(),
    watch_user_date_controller_saga(),
    // Tickers
    watch_liste_tickers_saga(),
    watch_mes_tickers_15j_stat_saga(),
    watch_mes_tickers_15j_saga(),
    watch_mes_tickers_1mois_saga(),
    watch_mes_tickers_sans_activite_saga(),
    watch_mes_tickers_passif_saga(),
    watch_mes_tickers_marques_saga(),
    watch_liste_tickers_search_saga(),
    watch_info_ticker_saga(),
    watch_year_info_ticker_saga(),
    watch_add_ticker_saga(),
    watch_update_ticker_saga(),
    watch_contact_ticker_saga(),
    watch_add_contact_ticker_saga(),
    watch_edit_contact_ticker_saga(),
    watch_am_ticker_saga(),
    watch_date_am_ticker_saga(),
    watch_membre_ticker_saga(),
    watch_add_am_ticker_saga(),
    watch_add_membre_ticker_saga(),
    watch_edit_membre_ticker_saga(),
    watch_edit_am_ticker_saga(),
    watch_depense_ticker_saga(),
    watch_allocation_ticker_saga(),
    watch_liste_tickers_alertes_saga(),
    watch_liste_statuts_tickers_saga(),
    watch_liste_categories_saga(),
    watch_liste_type_categories_saga(),
    watch_liste_users_saga(),
    watch_liste_am_saga(),
    watch_liste_user_team_saga(),
    watch_liste_user_times_download_saga(),
    watch_liste_marque_saga(),
    watch_liste_marque_search_saga(),
    watch_liste_client_saga(),
    watch_liste_bu_saga(),
    watch_ticker_user_stat_saga(),
    watch_ticker_date_controller_saga(),
    watch_liste_lettre_ticker_saga(),
    watch_liste_tickers_search_value_saga(),
    watch_liste_tickers_super_user_saga(),
    watch_send_message_retard_saga(),
    // Timesheet
    watch_next_day_saga(),
    watch_day_before_saga(),
    watch_ticker_admin_saga(),
    watch_ticker_saved_saga(),
    watch_publish_timesheet_saga(),
    watch_suivi_timesheet_saga(),
    // Facture
    watch_liste_allocation_offre_saga(),
    watch_liste_facturation_saga(),
    watch_liste_facture_saga(),
    watch_entite_saga(),
    watch_statut_facture_saga(),
    watch_revenue_liste_saga(),
    watch_add_facture_saga(),
    watch_update_facture_saga(),
    watch_liste_allocation_offre_facture_saga(),
    watch_facture_ticker_saga(),
    watch_statistique_facture_saga(),
    // Client
    watch_liste_clients_saga(),
    watch_add_clients_saga(),
    watch_liste_contacts_saga(),
    watch_rapport_client_saga(),
    watch_detail_client_saga(),
    watch_update_client_saga(),
    watch_client_user_stat_saga(),
    watch_client_date_controller_saga(),
    watch_depense_client_saga(),
    watch_facture_client_saga(),
    watch_ticker_client_saga(),
    // watch_marque_client_saga(),
    // Marque
    watch_liste_marques_saga(),
    watch_add_marque_saga(),
    watch_detail_marque_saga(),
    watch_update_marque_saga(),
    watch_allocation_marque_saga(),
    watch_marque_user_stat_saga(),
    watch_marque_date_controller_saga(),
    watch_depense_marque_saga(),
    watch_allocation_client_saga(),
    watch_facture_marque_saga(),
    // Fournisseurs
    watch_liste_fournisseurs_saga(),
    watch_liste_fournisseurs_all_saga(),
    watch_info_fournisseurs_saga(),
    watch_add_fournisseurs_saga(),
    watch_update_fournisseurs_saga(),
    watch_liste_custom_values_fournisseurs_saga(),
    watch_liste_contact_fournisseurs_saga(),
    watch_liste_files_fournisseurs_saga(),
    watch_add_files_fournisseurs_saga(),
    watch_update_files_fournisseurs_saga(),
    watch_liste_categorie_fournisseurs_saga(),
    watch_liste_sous_categorie_fournisseurs_saga(),
    watch_liste_custom_values_saga(),
    watch_liste_facture_fournisseur_saga(),
    watch_add_facture_fournisseur_saga(),
    watch_update_facture_fournisseur_saga(),
    watch_liste_allocation_offre_fournisseur_saga(),
    watch_statut_facture_fournisseur_saga(),
    watch_liste_allocation_offre_facture_fournisseur_saga(),
    watch_add_contacts_fournisseurs_saga(),
    // Conge
    watch_liste_conge_saga(),
    watch_liste_conge_valide_saga(),
    watch_liste_conge_rejete_saga(),
    watch_liste_conge_annulee_saga(),
    watch_liste_sub_conge_saga(),
    watch_liste_sub_conge_valide_saga(),
    watch_liste_sub_conge_rejete_saga(),
    watch_liste_all_conge_saga(),
    watch_liste_all_conge_valide_saga(),
    watch_liste_all_conge_rejete_saga(),
    watch_add_conge_saga(),
    watch_edit_conge_saga(),
    watch_annuler_conge_saga(),
    watch_upload_conge_saga(),
    watch_validate_conge_saga(),
    watch_liste_solde_saga(),
    watch_liste_solde_team_saga(),
    watch_used_solde_saga(),
    watch_liste_conge_type_saga(),
    // Attestation
    watch_add_attestation_saga(),
    watch_validate_attestation_saga(),
    watch_get_types_saga(),
    watch_get_attestation_saga(),
    watch_get_attestation_cours_saga(),
    watch_get_attestation_signe_saga(),
    watch_get_all_attestation_saga(),
    watch_get_all_attestation_cours_saga(),
    watch_get_all_attestation_signe_saga(),
    // explication
    watch_liste_rapport_user_ticker_saga(),
    watch_liste_explication_saga(),
    watch_create_demande_explication_saga(),
    watch_update_demande_explication_saga(),
    watch_create_reponse_explication_saga(),
    watch_liste_explication_author_saga(),
    // Non staffe
    watch_liste_team_disponible_saga(),
    watch_user_disponible_saga(),
    watch_create_non_staffe_saga(),
    watch_update_non_staffe_saga(),
    // noteFrais
    watch_get_ordre_mission_saga(),
    watch_get_remboursement_saga(),
    watch_get_remboursement_valide_saga(),
    watch_get_remboursement_rembourse_saga(),
    watch_get_remboursement_rejete_saga(),
    watch_get_moyen_paiement_saga(),
    watch_get_moyen_remboursement_saga(),
    watch_categorie_remboursement_saga(),
    watch_statut_ordre_saga(),
    watch_statut_remboursement_saga(),
    watch_create_order_saga(),
    watch_update_order_saga(),
    watch_delete_order_saga(),
    watch_create_refund_saga(),
    watch_update_refund_saga(),
    watch_delete_refund_saga(),
    // maladie
    watch_add_maladie_saga(),
    watch_edit_maladie_saga(),
    watch_list_maladie_saga(),
    watch_list_maladie_uploade_saga(),
    watch_list_maladie_valide_saga(),
    watch_list_all_maladie_saga(),
    watch_list_all_maladie_uploade_saga(),
    watch_list_all_maladie_valide_saga(),
  ])
}
