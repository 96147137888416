// Liste conge
export function get_conge_action(payload) {
  return {
    type: "LISTE_CONGE",
    payload,
  }
}

export function get_conge_action_done(request) {
  return {
    type: "LISTE_CONGE_DONE",
    payload: request,
  }
}

// Liste conge valide
export function get_conge_valide_action(payload) {
  return {
    type: "LISTE_CONGE_VALIDE",
    payload,
  }
}

export function get_conge_valide_action_done(request) {
  return {
    type: "LISTE_CONGE_VALIDE_DONE",
    payload: request,
  }
}

// Liste conge refuse
export function get_conge_rejete_action(payload) {
  return {
    type: "LISTE_CONGE_REFUSE",
    payload,
  }
}

export function get_conge_rejete_action_done(request) {
  return {
    type: "LISTE_CONGE_REFUSE_DONE",
    payload: request,
  }
}

// Liste conge annule
export function get_conge_annule_action(payload) {
  return {
    type: "LISTE_CONGE_ANNULE",
    payload,
  }
}

export function get_conge_annule_action_done(request) {
  return {
    type: "LISTE_CONGE_ANNULE_DONE",
    payload: request,
  }
}

// Liste sub conge
export function get_sub_conge_action(payload) {
  return {
    type: "LISTE_SUB_CONGE",
    payload,
  }
}

export function get_sub_conge_action_done(request) {
  return {
    type: "LISTE_SUB_CONGE_DONE",
    payload: request,
  }
}

// Liste sub conge valide
export function get_sub_conge_valide_action(payload) {
  return {
    type: "LISTE_SUB_CONGE_VALIDE",
    payload,
  }
}

export function get_sub_conge_valide_action_done(request) {
  return {
    type: "LISTE_SUB_CONGE_VALIDE_DONE",
    payload: request,
  }
}

// Liste sub conge refuse
export function get_sub_conge_rejete_action(payload) {
  return {
    type: "LISTE_SUB_CONGE_REFUSE",
    payload,
  }
}

export function get_sub_conge_rejete_action_done(request) {
  return {
    type: "LISTE_SUB_CONGE_REFUSE_DONE",
    payload: request,
  }
}

// Liste all conge
export function get_all_conge_action(payload) {
  return {
    type: "LISTE_ALL_CONGE",
    payload,
  }
}

export function get_all_conge_action_done(request) {
  return {
    type: "LISTE_ALL_CONGE_DONE",
    payload: request,
  }
}

// Liste all conge valide
export function get_all_conge_valide_action(payload) {
  return {
    type: "LISTE_ALL_CONGE_VALIDE",
    payload,
  }
}

export function get_all_conge_valide_action_done(request) {
  return {
    type: "LISTE_ALL_CONGE_VALIDE_DONE",
    payload: request,
  }
}

// Liste all conge refuse
export function get_all_conge_rejete_action(payload) {
  return {
    type: "LISTE_ALL_CONGE_REFUSE",
    payload,
  }
}

export function get_all_conge_rejete_action_done(request) {
  return {
    type: "LISTE_ALL_CONGE_REFUSE_DONE",
    payload: request,
  }
}

// Add conge
export function get_add_conge_action(payload) {
  return {
    type: "ADD_CONGE",
    payload,
  }
}

export function get_add_conge_action_done(request) {
  return {
    type: "ADD_CONGE_DONE",
    payload: request,
  }
}

// Edit conge
export function get_edit_conge_action(payload) {
  return {
    type: "EDIT_CONGE",
    payload,
  }
}

export function get_edit_conge_action_done(request) {
  return {
    type: "EDIT_CONGE_DONE",
    payload: request,
  }
}

// Annuler conge
export function Annuler_conge_action(payload) {
  return {
    type: "ANNULER_CONGE",
    payload,
  }
}

export function annuler_conge_action_done(request) {
  return {
    type: "ANNULER_CONGE_DONE",
    payload: request,
  }
}

// Upload conge
export function upload_conge_action(payload) {
  return {
    type: "UPLOAD_CONGE",
    payload,
  }
}

export function upload_conge_action_done(request) {
  return {
    type: "UPLOAD_CONGE_DONE",
    payload: request,
  }
}

// Liste Solde
export function get_solde_action(payload) {
  return {
    type: "SOLDE_GAIN",
    payload,
  }
}

export function get_solde_action_done(request) {
  return {
    type: "SOLDE_GAIN_DONE",
    payload: request,
  }
}

// used conge
export function used_conge_action(payload) {
  return {
    type: "USED_CONGE",
    payload,
  }
}

export function used_conge_action_done(request) {
  return {
    type: "USED_CONGE_DONE",
    payload: request,
  }
}

// Team conge
export function team_conge_action(payload) {
  return {
    type: "TEAM_CONGE",
    payload,
  }
}

export function team_conge_action_done(request) {
  return {
    type: "TEAM_CONGE_DONE",
    payload: request,
  }
}

// Validate conge
export function validate_conge_action(payload) {
  return {
    type: "VALIDATE_CONGE",
    payload,
  }
}

export function validate_conge_action_done(request) {
  return {
    type: "VALIDATE_CONGE_DONE",
    payload: request,
  }
}

export function change_statut_changed() {
  return {
    type: "RESET_STATUT_CHANGED",
  }
}

// Liste conge type
export function liste_conge_type_action(payload) {
  return {
    type: "LISTE_CONGE_TYPE",
    payload,
  }
}

export function liste_conge_type_action_done(request) {
  return {
    type: "LISTE_CONGE_TYPE_DONE",
    payload: request,
  }
}
